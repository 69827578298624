<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.companyManagement')"
    id="companyManagement"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
        <el-button
          v-has="'编辑公司'"
          class="fr"
          type="text"
          @click="setIsEdit(false)"
        >
          {{ $t("i18n.edit") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form
          :columns="basicColumns"
          @onSubmit="onSubmit"
          @resetForm="setIsEdit(true)"
        ></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.elevatorList") }}</span>
        <el-button class="fr" type="text" @click="newElevatorVisible = true">
          {{ $t("i18n.addElevator") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-table
          @init="getElevatorList"
          @handleDelete="handleDelete"
          :tableData="elevatorData"
          :options="elevatorOptions"
          :columns="elevatorColumns"
          :operates="elevatorOperates"
          :total="elevatorTotal"
          :height="300"
        >
        </jl-table>
      </div>
    </el-card>
    <!-- <el-card class="box-card m-t">
      <template #header>
        <span>{{ $t("i18n.regionAttribute") }}</span>
        </el-button>
      </template>
    </el-card> -->
    <el-dialog
      :title="$t('i18n.addElevator')"
      v-model="newElevatorVisible"
      :close-on-click-modal="false"
      width="80%"
      destroy-on-close
    >
      <jl-table
        @init="getAddEleList"
        :tableData="addEleData"
        :options="addEleOptions"
        :columns="addEleColumns"
        :operates="addEleOperates"
        :total="addEleTotal"
        @handleSelectionChange="
          (val) => {
            handleSelectionChange(val, 'selectNewData');
          }
        "
        height="500"
      ></jl-table>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "CompanyMaintaincou",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      basicColumns: [
        {
          prop: "name",
          label: "companyName",
          type: "input",
          readonly: true,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "comLogo",
          label: "comLogo",
          type: "upload",
          readonly: true,
          action: "company",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png文件",
        },
        {
          prop: "address",
          label: "companyAddress",
          type: "input",
          readonly: true,
        },
        {
          prop: "phone",
          label: "telephone",
          type: "input",
          readonly: true,
        },
        {
          prop: "contact",
          label: "contactPerson",
          type: "input",
          readonly: true,
        },
        {
          prop: "mobile",
          label: "mobilePhone",
          type: "input",
          readonly: true,
        },
      ],
      elevatorData: [],
      elevatorTotal: 0,
      elevatorOptions: {
        isDelete: true,
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      elevatorColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          type: "input",
          search: true,
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "center",
        },
        {
          prop: "address",
          label: "address",
          align: "left",
        },
      ],
      elevatorOperates: {
        width: 100,
        fixed: "right",
        list: [],
      },
      companyDetail: "",
      newElevatorVisible: false, // 新增电梯弹窗
      addEleColumns: [
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 300,
          search: true,
          type: "input",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
        // {
        //   prop: "cityId",
        //   label: "city",
        //   type: "remote",
        //   rows: false,
        //   search: true,
        //   props: { label: "name", value: "id" },
        //   loading: false,
        //   data: [],
        //   remoteMethod: (val) => {
        //     remoteCityMethod(val, "addEleColumns", "cityId");
        //   },
        // },
        // {
        //   prop: "companyId",
        //   label: "companyName",
        //   type: "remote",
        //   rows: false,
        //   search: true,
        //   props: { label: "name", value: "id" },
        //   loading: false,
        //   data: [],
        //   remoteMethod: (val) => {
        //     remoteCompanyMethod(val, "addEleColumns", "companyId");
        //   },
        // },
      ],
      addEleOperates: {
        width: 100,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: (formInline) => {
              addNewElevator(formInline, false);
            },
          },
          {
            id: "1",
            label: "addElevator",
            icon: "el-icon-plus",
            class: "add",
            show: true,
            type: "outer",
            method: (formInline) => {
              addNewElevator(formInline, false);
            },
          },
        ],
      },
      addEleOptions: {
        loading: false, // 是否添加表格loading加载动画
        highlightCurrentRow: true, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      addEleTotal: 0,
      addEleData: [],
      selectNewData: [], // 选中未添加到合同的电梯
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.system.companyDetail(id);
      state.companyDetail = data;
      setIsEdit(true, data);
    };

    const onSubmit = async (row) => {
      let data = JSON.parse(JSON.stringify(row));
      data.id = route.query.id;
      if (Array.isArray(data.comLogo)) {
        let urls = [];
        data.comLogo.map((item) => {
          urls.push(item.url);
        });
        data.comLogo = urls.join(",");
      }
      await proxy.$api.system.editCompany(data);
      proxy.$defined.tip(t("i18n.edit") + t("i18n.success"), "success");
      init();
    };

    const getElevatorList = async (params) => {
      params.id = route.query.id;
      const { data } = await proxy.$api.system.companyElevator(params);
      state.elevatorData = data.records;
      state.elevatorTotal = data.total;
    };

    const addNewElevator = async (formInline, total) => {
      // 新增电梯到合同
      var ids = checkSelectEle("selectNewData");
      if (ids.length > 0) {
        await proxy.$api.system.addElevatorToCompany({
          cityId: "",
          companyId: route.query.id,
          elevatorIds: ids,
          number: formInline.number,
          otherCompanyId: "",
          projectName: formInline.projectName,
          total: total, // true 全部 false 选中
        });
        proxy.$defined.tip(t("i18n.new") + t("i18n.success"), "success");
        state.newElevatorVisible = false;
        getElevatorList(state.paging);
      }
    };

    const checkSelectEle = (key) => {
      if (state[key].length > 0) {
        return state[key];
      } else {
        proxy.$defined.tip(t("i18n.selectElevators"));
        return "";
      }
    };

    const handleSelectionChange = (val, key) => {
      let elevatorIds = [];
      val.map((ele) => {
        elevatorIds.push(ele.id);
      });
      state[key] = elevatorIds;
    };

    const setIsEdit = (flag, data) => {
      state.basicColumns.map((item) => {
        item.readonly = flag;
        item.isUpdate = true;
        if (data && data[item.prop] != null && data[item.prop] != undefined) {
          if (item.type === "upload") {
            let files = [];
            data[item.prop].split(",").map((f) => {
              let name = f.split("/")[f.split("/").length - 1];
              let obj = {
                name: name,
                url: f,
              };
              files.push(obj);
            });
            item.value = files;
          } else {
            item.value = data[item.prop];
          }
        }
      });
    };

    const getAddEleList = async (params) => {
      // 加载未添加的电梯
      params.id = route.query.id;
      const { data } = await proxy.$api.system.companyNotElevator(params);
      state.addEleData = data.records;
      state.addEleTotal = data.total;
    };

    const handleDelete = (row, pages) => {
      proxy.$api.system
        .delElevatorToCompany({
          companyId: route.query.id,
          elevatorIds: [row.id],
        })
        .then(() => {
          getElevatorList(pages);
          proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
        });
    };
    const remoteCityMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.system.getRegion({ name: val }).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    const remoteCompanyMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.system.getAllCompany({ name: val }).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      getElevatorList,
      handleSelectionChange,
      setIsEdit,
      addNewElevator,
      getAddEleList,
      handleDelete,
      remoteCityMethod,
      remoteCompanyMethod,
    };
  },
};
</script>

<style lang="scss">
#companyManagement {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
</style>
